import _ from 'lodash';

export const isBlock = $op => $op === 'and' || $op === 'or' || $op === 'checker-list';

const isEmpty = val => val == null || val === '' || (typeof val === 'string' && val.trim() === '');

export const concatPath = (a, b) => {
    const aEmpty = isEmpty(a);
    const bEmpty = isEmpty(b);

    return !aEmpty && !bEmpty ? a + '.' + b : aEmpty ? b : a;
};

// Iterates over every checker-message (and $checker prop) of a structure
export const messageIterate = (checkerStructure, iterator) => {
    const exec = (obj, path) => {
        if (typeof obj !== 'object' || obj.$op !== 'checker-message') return;
        iterator(obj, path);
    }

    structIterate(checkerStructure, (obj, path) => {
        if (obj.$op === 'checker-message') exec(obj, path);
        else if (obj.$checker) _.each(obj.$checker, (obj, key) => exec(obj, concatPath(path, '$checker.' + key)));
    });
};

export const getMaxMessagePriority = checkerStructure => {
    let maxPriority = -1;

    messageIterate(checkerStructure, obj => {
        maxPriority = Math.max(maxPriority, obj.priority || -1);
    });

    return maxPriority;
}

// Iterates over each relevant expression of a formula (formula.structure + formula.functions)
export const formulaIterate = (formula, iterator) => {
    const allStructs = [['structure'], ['checker'], ['filter']].concat(
        _.map(formula.functions, (item, index) => ['functions', index, 'structure'])
    );

    for (let path of allStructs) {
        const structure = _.get(formula, path);
        if (!structure) continue;
        structIterate(structure, iterator, path);
    }
}

export const structIterate = (structure, fn, path=[], parent=null) => {
    if (structure == null) return;
    if (typeof structure !== 'object') return;

    const next = (obj, key) => structIterate(obj, fn, path.concat(key), structure);

    fn(structure, path.join('.'), parent);

    switch (structure.$op) {
        case 'if':
            next(structure.$value, ['$value']);
            next(structure.$then, ['$then']);
            next(structure.$else, ['$else']);
            break;
        case 'for':
            next(structure.$value, ['$value']);
            break
        case 'and':
        case 'or':
        case 'checker-list':
            structure.$value.forEach((item, index) => next(item, ['$value', index]));
            break;
        case 'checker-table':
            structure.$value.forEach((item, index) => next(item, ['$value', index]));
            structure.$args.forEach((item, index) => next(item, ['$args', index]));
            break;
        case 'schema':
            // This was a hack before. Ideally this should remain disabled
            // structure.$args.forEach((item, index) => next(item, ['$args', index]));
            break;
        case 'ref':
        case 'fn':
        case 'call':
        case 'checker-message':
        case 'checker-var':
            break;
        default:
            console.warn(structure);
            throw new Error(`Unknown operation: '${structure.$op}'`);
    }
}

// Removes all the redux-specific annotations from the formula
export const cleanFormula = formula => {
    const clone = JSON.parse(JSON.stringify(formula));

    const allStructs = [
        clone.structure,
        clone.checker,
        ..._.map(clone.functions, 'structure'),
    ].filter(x => x);

    const clean = obj => {
        for (let key in obj) {
            if (key[0] === '$') continue;
            delete obj[key];
        }

        // legacy prop
        if (obj.$checker) {
            delete obj.$checker.template;
            for (let key in obj.$checker) {
                if (typeof obj.$checker[key] === 'string') continue;
                clean(obj.$checker[key]);
            }
        }

        if (!_.size(obj.$checker)) delete obj.$checker;
    }

    for (let struct of allStructs) structIterate(struct, clean);

    if (clone.options) delete clone.options;
    if (clone.presets) delete clone.presets;

    return clone;
}
